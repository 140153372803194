import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip as ReactTooltip } from "react-tooltip";

import yesicon from "../../assets/images/safetyProtection/yes-icon.png";
import noicon from "../../assets/images/safetyProtection/no-icon.png";
import naicon from "../../assets/images/safetyProtection/no-image.png";
import tripIcon from "../../assets/images/TripreportIcon.png";
import distanceIcon from "../../assets/images/distanceNewIcon.png";

import logo from "../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MyRidesController from "../../controller/Rides/MyRides.js";
import ValueFormat from "../../util/ValueFormat.js";
import {
    RIDEDETAILS,
    SC_LOGIN_USER_FIRST_NAME,
    SC_USER_FIRST_NAME,
    SC_USER_ID,
    SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../store/actions";
import Loader from "../../components/Common/loader";
import MyRidedataList from "./myridetabledata";
import { PiDownloadSimple } from "react-icons/pi";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bluetooth from "../../assets/images/Bluetooth.png";
import greenCar from "../../assets/images/car_icon.png";
import redCar from "../../assets/images/redCar.png";
import sandClock from "../../assets/images/sand_clock.png";
import startIcon from "../../assets/images/start_icon.png";
import calendar from "../../assets/images/calendar_icon.png";
import gpsRoute from "../../assets/images/gps_route_icon.png";
import destinationIcon from "../../assets/images/destination_icon.png";
import startOverlay from "../../assets/images/start_overlay.png";
import dotted from "../../assets/images/dotted_vertical_line.png";
import back from "../../assets/images/back.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import baterryStart from "../../assets/images/batteryHigh.svg";
import baterryEnd from "../../assets/images/baterryLow.svg";
import Converter from "../../components/Common/EncodeDecode.js";
import i1 from "../../assets/images/adminDashboard/ad-dr-name.svg"
import i2 from "../../assets/images/adminDashboard/ad-dr-uname.svg"
import i3 from "../../assets/images/adminDashboard/ad-dr-rank.svg"
import i4 from "../../assets/images/adminDashboard/totalRides.svg"
import i5 from "../../assets/images/adminDashboard/ad-dr-riskAlerts.svg"
import i6 from "../../assets/images/adminDashboard/ad-dr-otherAlerts.svg"
import category_image from "../../assets/images/adminDashboard/category.svg"
import ride_name from "../../assets/images/adminDashboard/ride_name.svg"
import i7 from "../../assets/images/adminDashboard/ad-dr-overspeed.svg"
import i8 from "../../assets/images/adminDashboard/ad-dr-mobileCall.svg"
import i9 from "../../assets/images/adminDashboard/ad-dr-mobileScreen.svg"
import i10 from "../../assets/images/adminDashboard/ad-dr-acceleration.svg"
import i11 from "../../assets/images/adminDashboard/ad-dr-Braking.svg"
import i12 from "../../assets/images/adminDashboard/ad-dr-cornering.svg"
import i13 from "../../assets/images/adminDashboard/ad-dr-score.svg"
import crash from "../../assets/images/adminDashboard/crash_accident.svg"
import DetailViewd from "../../assets/images/rideDetails/DetailedView.svg"
import UBIDrivingScore from "../../assets/images/Ubi Images/UBIDrivingScore.svg"
import UBIAnticipation from "../../assets/images/Ubi Images/UBIAnticipation.svg"
import UBISelfConfidence from "../../assets/images/Ubi Images/UBISelfConfidence.svg"
import UBIDrivingSkill from "../../assets/images/Ubi Images/UBIDrivingSkill.svg"
import UBIDrivingStyle from "../../assets/images/Ubi Images/UBIDrivingStyle.svg"
import UBIDrivingState from "../../assets/images/Ubi Images/UBIDrivingState.svg"
import UBIMobileScreenUse from "../../assets/images/Ubi Images/UBIMobileScreenUse.svg"
import UBIMobileCallUse from "../../assets/images/Ubi Images/UBIMobileCallUse.svg"
import UBIKmsDriven from "../../assets/images/Ubi Images/UBIKmsDriven.svg"
import UBIStressStrain from "../../assets/images/Ubi Images/UBIStressStrain.svg"
import UBIScoreImage from "../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg"
import tdistance from "../../assets/images/adminDashboard/ad-2r-distance.svg"
import tduration from "../../assets/images/adminDashboard/ad-2r-time.svg"
import battery from "../../assets/images/adminDashboard/battery_new.svg"
import clock from "../../assets/images/adminDashboard/clock_new.svg"


const TableStyle = {
    table: {
        width: "100%",
        display: "table",
        borderSpacing: 0,
        border: "0.2px",
        margin: "0px",
    },
    th: {
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: "#fff",
        height: "20px",
        fontWeight: 600,
        fontSize: "14px",
    },
};


function NewRideList() {
    const history = useNavigate();
    const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);
    const [workFilter, setWorkFilter] = useState("");
    const [categoryValue, setCategory] = useState("");
    const [loginType, setLoginType] = useState("");
    const [filter, setFilter] = useState("CURRENT_YEAR");
    const [excelData, setExcelData] = useState([]);
    const [ddData, setDDData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [nextShow, setNextShow] = useState();
    const [prevShow, setPrevShow] = useState();
    const [totalPageCount, setTotalPageCount] = useState();
    const [apiData, setApiData] = useState([]);
    const [indexStart, setIndexStart] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [dataLength, setDataLength] = useState(null);
    const [userId, setUserId] = useState(localStorage.getItem(SC_USER_ID));
    const [startDateTime, setStartDateTime] = useState("");
    const [endDateTime, setEndDateTime] = useState("");
    const [count, setCount] = useState(0);

    const pageSize = 5;
    const inputRef = useRef();
    const [modalIsOpen, setIsOpen] = useState(false);

    const [userFirstName, setUserFirstName] = useState(
        localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
    );
    const [userName, setUserName] = useState(
        localStorage.getItem(SC_USER_FIRST_NAME)
    );
    const previous = () => {
        setIndexStart(indexStart - pageSize);
        setPageNumber(pageNumber - 1);
    };
    const next = () => {
        setIndexStart(indexStart + pageSize);
        setPageNumber(pageNumber + 1);
    };
    const [showLoader, setShowLoader] = useState(false);

    // const exportExcelData = async () => {
    //     var dataExcel = [];
    //     var ddExcel = [];
    //     var result = await MyRidesController.myRidesList(
    //         indexStart,
    //         filter,
    //         workFilter,
    //         categoryValue,
    //         searchText,
    //         250,
    //         startDateTime,
    //         endDateTime
    //     );
    //     var resultJSON = JSON.parse(result);
    //     var status = resultJSON.status;
    //     var resultJSONData = resultJSON.data;
    //     // setDataLength(resultJSONData.length);
    //     // console.log(
    //     //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    //     // );
    //     if (status === "SUCCESS") {
    //         if (resultJSONData.length > 0) {
    //             for (let i = 0; i < resultJSONData.length; i++) {
    //                 var drivingScore = "0",
    //                     rideName = "N/A",
    //                     status = "N/A",
    //                     // origin ='N/A',
    //                     // subType = 'N/A',
    //                     alerts = 0,
    //                     createdTime = "N/A",
    //                     dayPercentage = "0",
    //                     nightPercentage = "100%" /*accessLevel = 'N/A',*/,
    //                     endTime = "N/A",
    //                     city = "N/A",
    //                     state = "N/A",
    //                     country = "N/A",
    //     /*locationName = 'N/A',*/ zipCode = "N/A",
    //                     anticipation = "0",
    //                     drivingSkill = "0",
    //                     selfConfidence = "0",
    //                     travelTime = "0Mins",
    //                     totalKiloMeter = "0Kms",
    //                     urbanPercent = "0%",
    //                     ruralPercent = "0%",
    //                     highwayPercent = "0%",
    //                     batteryLevelStart = "0%",
    //                     batteryLevelEnd = "0%",
    //                     category = "N/A";
    //                 var rideObject = resultJSONData[i];
    //                 var deviceField = rideObject.deviceField;

    //                 try {
    //                     // var totalPercentage = 100;

    //                     rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
    //                     status = rideObject.status.length > 0 ? rideObject.status : "N/A";
    //                     category = rideObject.category.length > 0 ? rideObject.category : "N/A";
    //                     // origin         = rideObject.origin.length > 0 ? rideObject.origin : 'N/A';
    //                     alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
    //                     createdTime =
    //                         rideObject.startDateTimeToTimeZone.length > 0
    //                             ? rideObject.startDateTimeToTimeZone
    //                             : "N/A";
    //                     // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
    //                     dayPercentage =
    //                         rideObject.dayPercentage > 0
    //                             ? parseFloat(rideObject.dayPercentage).toFixed(0)
    //                             : 0;
    //                     nightPercentage =
    //                         rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 100 + "%";
    //                     // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
    //                     // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
    //                     city =
    //                         deviceField.city != null && deviceField.city.length > 0
    //                             ? deviceField.city
    //                             : "N/A";
    //                     state =
    //                         deviceField.state != null && deviceField.state.length > 0
    //                             ? deviceField.state
    //                             : "N/A";
    //                     country =
    //                         deviceField.country != null && deviceField.country.length > 0
    //                             ? deviceField.country
    //                             : "N/A";
    //                     zipCode =
    //                         deviceField.zipCode != null && deviceField.zipCode > 0
    //                             ? deviceField.zipCode
    //                             : "N/A";

    //                     endTime =
    //                         rideObject.endDateTimeToTimeZone != null
    //                             ? rideObject.endDateTimeToTimeZone
    //                             : "N/A";
    //                     anticipation =
    //                         rideObject.anticipation != null && rideObject.anticipation > 0
    //                             ? parseFloat(rideObject.anticipation).toFixed(0)
    //                             : 0;
    //                     drivingSkill =
    //                         rideObject.drivingSkill != null && rideObject.drivingSkill > 0
    //                             ? parseFloat(rideObject.drivingSkill).toFixed(0)
    //                             : 0;
    //                     drivingScore =
    //                         rideObject.drivingScore != null && rideObject.drivingScore > 0
    //                             ? parseFloat(rideObject.drivingScore).toFixed(0)
    //                             : 0;
    //                     selfConfidence =
    //                         rideObject.selfConfidence != null && rideObject.selfConfidence > 0
    //                             ? parseFloat(rideObject.selfConfidence).toFixed(2)
    //                             : 0;
    //                     travelTime =
    //                         rideObject.travelTime != null && rideObject.travelTime > 0
    //                             ? parseFloat(rideObject.travelTime).toFixed(2) + " Mins"
    //                             : 0 + " Mins";
    //                     totalKiloMeter =
    //                         rideObject.kiloMeter != null && rideObject.kiloMeter > 0
    //                             ? parseFloat(rideObject.kiloMeter).toFixed(2) + " Kms"
    //                             : 0 + " Kms";
    //                     urbanPercent =
    //                         rideObject.urbanPercent != null && rideObject.urbanPercent > 0
    //                             ? parseFloat(rideObject.urbanPercent).toFixed(0) + "%"
    //                             : 0 + "%";
    //                     ruralPercent =
    //                         rideObject.ruralPercent != null && rideObject.ruralPercent > 0
    //                             ? parseFloat(rideObject.ruralPercent).toFixed(0) + "%"
    //                             : 0 + "%";
    //                     highwayPercent =
    //                         rideObject.highwayPercent != null && rideObject.highwayPercent > 0
    //                             ? parseFloat(rideObject.highwayPercent).toFixed(0) + "%"
    //                             : 0 + "%";
    //                     batteryLevelStart =
    //                         rideObject.batteryLevelStart != null &&
    //                             rideObject.batteryLevelStart > 0
    //                             ? parseFloat(rideObject.batteryLevelStart).toFixed(0) + "%"
    //                             : 0 + "%";
    //                     batteryLevelEnd =
    //                         rideObject.batteryLevelEnd != null && rideObject.batteryLevelEnd > 0
    //                             ? parseFloat(rideObject.batteryLevelEnd).toFixed(0) + "%"
    //                             : 0 + "%";
    //                 } catch (errMess) {
    //                     // console.log("MyRide Export Excel Error Message :" + errMess);
    //                 }

    //                 var userDetails = {
    //                     "Ride No": rideName,
    //                     Status: status,
    //                     "Driving Score": drivingScore,
    //                     "Driving Skill": drivingSkill,
    //                     Anticipation: anticipation,
    //                     "Self Confidence": selfConfidence,
    //                     "Start Time": createdTime,
    //                     "End Time": endTime,
    //                     "No Of Alerts": alerts,
    //                     "Day Percentage": dayPercentage + "%",
    //                     "Night Percentage": nightPercentage,
    //                     // 'From - Location Name' : locationName,
    //                     "To-City": city,
    //                     "To-State": state,
    //                     "To-Country": country,
    //                     "To-Zip Code": zipCode,
    //                     "Urban Percentage": urbanPercent,
    //                     "Rural Percentage": ruralPercent,
    //                     "Highway Percentage": highwayPercent,
    //                     // 'Mobile' : origin,
    //                     // 'Event' : subType,
    //                     Distance: totalKiloMeter,
    //                     Duration: travelTime,
    //                     "Battery Level Start": batteryLevelStart,
    //                     "Battery Level End": batteryLevelEnd,
    //                     Category: category,
    //                 };

    //                 var ddDetails = {
    //                     "Ride No": rideName,
    //                     "Ride date/time": createdTime,
    //                     Distance: totalKiloMeter,
    //                     Duration: travelTime,
    //                 };
    //                 dataExcel.push(userDetails);
    //                 ddExcel.push(ddDetails);
    //             }
    //             setExcelData(dataExcel);
    //             setDDData(ddExcel);
    //         } else {
    //             setExcelData([{}]);
    //             setDDData([]);
    //         }
    //     } else {
    //         console.log("Excel api failed");
    //         setExcelData([{}]);
    //         setDDData([]);
    //     }
    // };

    // useEffect(() => {
    //     exportExcelData();
    // }, [filter, workFilter, categoryValue, searchText])

    const newRideListGet = async (indexStartOfSet) => {
        try {
            setShowLoader(true);
            var result = await MyRidesController.newMyRidesList(
                indexStartOfSet,
                filter,
                workFilter,
                categoryValue,
                searchText,
                pageSize,
                startDateTime,
                endDateTime
            );
            setShowLoader(false);
            var resultJSON = JSON.parse(result);
            var status = resultJSON.status;
            var updatedTableData = [];
            if (status == "SUCCESS") {
                var resultJSONData = resultJSON.data;
                setDataLength(resultJSONData.length);
                // console.log("Ride List Data: ", resultJSONData);
                for (let i = 0; i < resultJSONData.length; i++) {
                    var userRideDetail = resultJSONData[i];
                    var rideName = ValueFormat.parseAsString(userRideDetail.name);
                    var userId = ValueFormat.parseAsString(userRideDetail.userId);
                    var overallDrivingScore = ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 1) || 0;
                    var overallAnticipation = ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 1) || 0;
                    var overallSelfConfidence = ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 1) || 0;
                    var overallDrivingSkill = ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 1) || 0;
                    var dStyleValue = ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 1) || 0;
                    var dStateValue = ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 1) || 0;
                    var kmsDriven = ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 1) || 0;
                    var mobileCallValue = ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 1) || 0;
                    var mobileScreenValue = ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 1) || 0;
                    var abcPoint = ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 1) || 0;

                    var totalEfficiencyRaw =
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 1)) +
                        parseFloat(ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 1));

                    var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
                    var totalEfficiencyTV = ValueFormat.parseAsFloat(totalEfficiencyRaw, 2);

                    var overallDrivingScoreTooltipValue = ValueFormat.parseAsFloat(userRideDetail.drivingScorePoint, 2) || "-";
                    var overallAnticipationTooltipValue = ValueFormat.parseAsFloat(userRideDetail.anticipationPoint, 2) || "-";
                    var overallSelfConfidenceTooltipValue = ValueFormat.parseAsFloat(userRideDetail.selfConfidencePoint, 2) || "-";
                    var overallDrivingSkillTooltipValue = ValueFormat.parseAsFloat(userRideDetail.drivingSkillPoint, 2) || "-";
                    var dStyleValueTooltipValue = ValueFormat.parseAsFloat(userRideDetail.drivingStylePoint, 2) || "-";
                    var dStateValueTooltipValue = ValueFormat.parseAsFloat(userRideDetail.drivingStatePoint, 2) || "-";
                    var kmsDrivenTooltipValue = ValueFormat.parseAsFloat(userRideDetail.kmsDrivenPoint, 2) || "-";
                    var mobileCallValueTooltipValue = ValueFormat.parseAsFloat(userRideDetail.mobileCallPoint, 2) || "-";
                    var mobileScreenValueTooltipValue = ValueFormat.parseAsFloat(userRideDetail.mobileScreenPoint, 2) || "-";
                    var abcPointTooltipValue = ValueFormat.parseAsFloat(userRideDetail.accBrakCorPoint, 2) || "-";

                    var efficiency = ValueFormat.parseAsFloat(userRideDetail.drivingEfficiency, 0) || "-";
                    var efficiencyTooltipValue = ValueFormat.parseAsFloat(userRideDetail.drivingEfficiency, 2) || "-";

                    var totalSevereAccelerationLowCount = ValueFormat.parseAsFloat(userRideDetail.severeAccelerationLowCount, 2) || "-";
                    var totalSevereAccelerationMediumCount = ValueFormat.parseAsFloat(userRideDetail.severeAccelerationMediumCount, 2) || "-";
                    var totalSevereAccelerationHighCount = ValueFormat.parseAsFloat(userRideDetail.severeAccelerationHighCount, 2) || "-";

                    var totalAccelerationCount = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationMediumCount, 0))
                        + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationHighCount, 0));

                    var totalSevereBrakingLowCount = ValueFormat.parseAsFloat(userRideDetail.severeBrakingLowCount, 2) || "-";
                    var totalSevereBrakingMediumCount = ValueFormat.parseAsFloat(userRideDetail.severeBrakingMediumCount, 2) || "-";
                    var totalSevereBrakingHighCount = ValueFormat.parseAsFloat(userRideDetail.severeBrakingHighCount, 2) || "-";

                    var totalBrakingCount = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingMediumCount, 0))
                        + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingHighCount, 0));

                    var totalSevereCorneringLowCount = ValueFormat.parseAsFloat(userRideDetail.severeCorneringLowCount, 2) || "-";
                    var totalSevereCorneringMediumCount = ValueFormat.parseAsFloat(userRideDetail.severeCorneringMediumCount, 2) || "-";
                    var totalSevereCorneringHighCount = ValueFormat.parseAsFloat(userRideDetail.severeCorneringHighCount, 2) || "-";

                    var totalCorneringCount = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringMediumCount, 0))
                        + parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringHighCount, 0));

                    var totalMobileUseInAcceptedCount = ValueFormat.parseAsFloat(userRideDetail.mobileUseInAcceptedCount, 0) || "-";
                    var totalMobileUseInAcceptedDistance = ValueFormat.parseAsFloat(userRideDetail.mobileUseInAcceptedDistance, 0) || "-";
                    const parsedNum = parseFloat(userRideDetail.mobileUseInAcceptedDuration);
                    var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum) ? parsedNum : ValueFormat.parseAsFloat(userRideDetail.mobileUseInAcceptedDuration, 2) || "-";
                    var totalMobileUseInAcceptedDurationTV = ValueFormat.parseAsFloat(userRideDetail.mobileUseInAcceptedDuration, 2) || "-";

                    var totalMobileScreenScreenOnCount = ValueFormat.parseAsFloat(userRideDetail.mobileScreenScreenOnCount, 0) || "-";
                    var totalMobileScreenScreenOnDistance = ValueFormat.parseAsFloat(userRideDetail.mobileScreenScreenOnDistance, 0) || "-";
                    const parsedNumMobileScreen = parseFloat(userRideDetail.mobileScreenScreenOnDuration);
                    var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(userRideDetail.mobileScreenScreenOnDuration, 2) || "-";
                    var totalMobileScreenScreenOnDurationTV = ValueFormat.parseAsFloat(userRideDetail.mobileScreenScreenOnDuration, 2) || "-";

                    var totalOverSpeedCount = ValueFormat.parseAsFloat(userRideDetail.overSpeedCount, 0) || "-";
                    const parsedNumOverSpeed = parseFloat(userRideDetail.overSpeedDuration);
                    var totalOverSpeedDuration =  Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(userRideDetail.overSpeedDuration, 2) || "-";
                    var totalOverSpeedDurationTV = ValueFormat.parseAsFloat(userRideDetail.overSpeedDuration, 2) || "-";
                    var totalMediumRiskCount = ValueFormat.parseAsFloat(userRideDetail.mediumRiskCount, 0) || "-";
                    var totalHighRiskCount = ValueFormat.parseAsFloat(userRideDetail.highRiskCount, 0) || "-";

                    var tKiloMeter = ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 0) || "-";
                    var tKiloMeterTV = ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2) || "-";
                    var tTravelTime = ValueFormat.parseAsFloat(userRideDetail.travelTime, 0) || "-";
                    var tTravelTimeTV = ValueFormat.parseAsFloat(userRideDetail.travelTime, 2) || "-";

                    var crashCount = ValueFormat.parseAsString(userRideDetail.crashCount) || "-";
                    var categoryObject = ValueFormat.parseAsObject(userRideDetail.rideBox);
                    var categoryTemp = ValueFormat.parseAsInt(categoryObject.rideBox);
                    var categoryTooltip =
                        categoryTemp == 1
                            ? "Safe Driving"
                            : categoryTemp == 2
                                ? "Sportive Driving"
                                : categoryTemp == 3
                                    ? "Quite Safe<br/>Driving" // Adding a line break before "Driving"
                                    : categoryTemp == 4
                                        ? "Reckless Driving"
                                        : categoryTemp == 5
                                            ? "Quite Dangerous<br/>Driving" // Adding a line break before "Driving"
                                            : "Dangerous Driving";
                    var category =
                        categoryTemp == 1
                            ? "C1"
                            : categoryTemp == 2
                                ? "C2"
                                : categoryTemp == 3
                                    ? "C3"
                                    : categoryTemp == 4
                                        ? "C4"
                                        : categoryTemp == 5
                                            ? "C5"
                                            : "C6";

                    var status = ValueFormat.parseAsString(
                        ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.status)
                    );
                    var rideId = ValueFormat.parseAsString(userRideDetail.deviceId);
                    var statusTemp = "Completed";
                    if (searchText == "") {
                        if (
                            i === 0 &&
                            indexStartOfSet === 0 &&
                            status === "In progress"
                        ) {
                            statusTemp = "In progress";
                        } else if (
                            i === 0 &&
                            indexStartOfSet === 0 &&
                            status === "Registered"
                        ) {
                            statusTemp = "Registered";
                        } else if (status === "Completed") {
                            statusTemp = "Completed";
                        } else {
                            statusTemp = "Completed";
                        }
                    } else if (searchText != "") {
                        if (status === "Completed") {
                            statusTemp = "Completed";
                        } else {
                            statusTemp = "Completed";
                        }
                    }
                    var divisionId = ValueFormat.parseAsString(
                        userRideDetail.divisionId
                    );

                    var rideCategory = ValueFormat.parseAsString(userRideDetail.category);

                    var startTime = ValueFormat.parseAsString(userRideDetail.startDateTimeToTimeZone) || "-";
                    var endTime = ValueFormat.parseAsString(userRideDetail.endDateTimeToTimeZone) || "-";
                    var batterylevelStart = ValueFormat.parseAsFloat(userRideDetail?.batteryLevelStart, 0) || "-";
                    var batterylevelEnd = ValueFormat.parseAsFloat(userRideDetail?.batteryLevelEnd, 0) || "-";
                    var longEvent = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.subType));
                    var event = ValueFormat.parseAsString(userRideDetail.subType) || "";
                    var shortEvent = "";
                    if (event === "BACKGROUND_AUTO") {
                        shortEvent = "BGA"
                    } else if (event === "BACKGROUND_MANUAL") {
                        shortEvent = "BGM"
                    } else if (event === "FOREGROUND_AUTO") {
                        shortEvent = "FGA"
                    } else if (event === "FOREGROUND_MANUAL") {
                        shortEvent = "FGM"
                    } else {
                        shortEvent = "-"
                    }

                    var totalCount = ValueFormat.parseAsInt(userRideDetail.totalCount);
                    setTotalPageCount(Math.ceil(totalCount / pageSize));
                    setNextShow(totalCount - indexStartOfSet > pageSize);

                    var resultObject = {
                        rideName: rideName,
                        category: category,
                        categoryTooltip: categoryTooltip,
                        drivingScore: overallDrivingScore,
                        rideId: rideId,
                        userId: userId,
                        divisionId: divisionId,
                        status: status,
                        StatusTemp: statusTemp,
                        ABCPoint: abcPoint,
                        mobileScreenPointNew: mobileScreenValue,
                        mobileUsePointNew: mobileCallValue,
                        overallAnticipation: overallAnticipation,
                        overallSelfConfidence: overallSelfConfidence,
                        overallDrivingSkill: overallDrivingSkill,
                        totalKmSPoint: kmsDriven,
                        DSP: dStateValue,
                        DSTP: dStyleValue,
                        efficiency: efficiency,
                        totalEfficiency: totalEfficiency,
                        efficiencyTooltipValue: efficiencyTooltipValue,
                        crashCount: crashCount,
                        overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
                        overallAnticipationTooltipValue: overallAnticipationTooltipValue,
                        overallSelfConfidenceTooltipValue: overallSelfConfidenceTooltipValue,
                        overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
                        dStyleValueTooltipValue: dStyleValueTooltipValue,
                        dStateValueTooltipValue: dStateValueTooltipValue,
                        kmsDrivenTooltipValue: kmsDrivenTooltipValue,
                        mobileCallValueTooltipValue: mobileCallValueTooltipValue,
                        mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
                        abcPointTooltipValue: abcPointTooltipValue,
                        totalAccelerationCount: totalAccelerationCount,
                        totalBrakingCount: totalBrakingCount,
                        totalCorneringCount: totalCorneringCount,
                        totalOverSpeedDuration: totalOverSpeedDuration,
                        totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
                        totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
                        totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,
                        totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
                        totalMobileScreenScreenOnDistance: totalMobileScreenScreenOnDistance,
                        totalMobileScreenScreenOnDuration: totalMobileScreenScreenOnDuration,
                        totalOverSpeedCount: totalOverSpeedCount,
                        totalMediumRiskCount: totalMediumRiskCount,
                        totalHighRiskCount: totalHighRiskCount,
                        tKiloMeter: tKiloMeter,
                        tTravelTime: tTravelTime,
                        rideCategory: rideCategory,
                        startTime: startTime,
                        endTime: endTime,
                        batterylevelStart: batterylevelStart,
                        batterylevelEnd: batterylevelEnd,
                        event: shortEvent,
                        longEvent: longEvent,
                        totalOverSpeedDurationTV: totalOverSpeedDurationTV,
                        totalMobileScreenScreenOnDurationTV: totalMobileScreenScreenOnDurationTV,
                        totalMobileUseInAcceptedDurationTV: totalMobileUseInAcceptedDurationTV,
                        tKiloMeterTV: tKiloMeterTV,
                        tTravelTimeTV: tTravelTimeTV,
                        totalEfficiencyTV: totalEfficiencyTV
                    };
                    updatedTableData.push(resultObject);
                }
                setApiData(updatedTableData)
                // console.log(updatedTableData, "updatedTableDataupdatedTableData");
                
            }

        }
        catch (e) {
            console.log("Cath Error While Getting Ride List: ", e)
        };
        if (indexStartOfSet == 0) {
            setPrevShow(false);
        } else if (indexStartOfSet > 0) {
            setPrevShow(true);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setIndexStart(0);
            setPageNumber((current) => current - current + 1);
            event.preventDefault(); // Prevent page reload
            inputRef.current.click(); // Trigger the button click
        }
    };

    useEffect(() => {
        setPageNumber(1);
        if (indexStart == 0) setCount((prev) => prev + 1);
        else setIndexStart(0);
    }, [filter, workFilter, categoryValue, startDateTime, endDateTime]);

    useEffect(() => {
        if (count > 0) newRideListGet(indexStart);
    }, [indexStart, count]);

    const handleSearch = (e) => {
        e.preventDefault();
        newRideListGet(0);
    };

    useEffect(() => {
        setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
    }, [loginType]);

    // --------------------------------------------------------------------//

    return (
        <div className="page-content">
            {showLoader && <Loader />}
            <Container fluid>
                <Breadcrumbs title="Rides" breadcrumbItem="Rides" />
                <Row className="mt-2">
                    <Col className="col-12 mt-2">
                        <Card>
                            <CardHeader className="mb-4" style={{ padding: "0px" }}>
                                <Row>
                                    <Col
                                        xl={2}
                                        lg={2}
                                        style={{
                                            borderRight: "1px solid #DFDEDE",
                                            paddingRight: 0,
                                        }}
                                    >
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4
                                                className="card-title mb-1 mt-1"
                                                style={{ textAlign: "center" }}
                                            >
                                                Ride
                                            </h4>
                                        </div>
                                        <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                                            <button
                                                type="button"
                                                data-tooltip-id="allrides"
                                                className={
                                                    workFilter == ""
                                                        ? "btn btn-primary btn-sm me-1"
                                                        : "btn btn-soft-secondary btn-sm me-1"
                                                }
                                                onClick={() => setWorkFilter("")}
                                            >
                                                All
                                                <ReactTooltip
                                                    id="allrides"
                                                    place="top"
                                                    content="All"
                                                    style={{ background: "black" }}
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                data-tooltip-id="wrkrides"
                                                className={
                                                    workFilter == "WORK"
                                                        ? "btn btn-primary btn-sm me-1"
                                                        : "btn btn-soft-secondary btn-sm me-1"
                                                }
                                                onClick={() => setWorkFilter("WORK")}
                                            >
                                                Work
                                                <ReactTooltip
                                                    id="wrkrides"
                                                    place="top"
                                                    content="Work"
                                                    style={{ background: "black" }}
                                                />
                                            </button>{" "}
                                            <button
                                                type="button"
                                                data-tooltip-id="perrides"
                                                className={
                                                    workFilter == "PERSONAL"
                                                        ? "btn btn-primary btn-sm me-1"
                                                        : "btn btn-soft-secondary btn-sm me-1"
                                                }
                                                onClick={() => setWorkFilter("PERSONAL")}
                                            >
                                                Personal
                                                <ReactTooltip
                                                    id="perrides"
                                                    place="top"
                                                    content="Personal"
                                                    style={{ background: "black" }}
                                                />
                                            </button>{" "}
                                        </div>
                                    </Col>
                                    <Col
                                        xl={2}
                                        lg={3}
                                        style={{
                                            borderRight: "1px solid #DFDEDE",
                                            padding: 0,
                                        }}
                                    >
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4
                                                className="card-title mb-1 mt-1"
                                                style={{ textAlign: "center" }}
                                            >
                                                Category
                                            </h4>
                                        </div>

                                        <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                                            <button
                                                type="button"
                                                data-tooltip-id="Categoryall"
                                                className={
                                                    categoryValue == ""
                                                        ? "btn btn-primary btn-sm me-1"
                                                        : "btn btn-soft-secondary btn-sm me-1"
                                                }
                                                onClick={() => setCategory("")}
                                            >
                                                All
                                                <ReactTooltip
                                                    id="Categoryall"
                                                    place="top"
                                                    content="All"
                                                    style={{ background: "black" }}
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                data-tooltip-id="Categorydriver"
                                                className={
                                                    categoryValue == "RIDE_DRIVER"
                                                        ? "btn btn-primary btn-sm me-1"
                                                        : "btn btn-soft-secondary btn-sm me-1"
                                                }
                                                onClick={() => setCategory("RIDE_DRIVER")}
                                            >
                                                Driver
                                                <ReactTooltip
                                                    id="Categorydriver"
                                                    place="top"
                                                    content="Driver"
                                                    style={{ background: "black" }}
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                data-tooltip-id="CategoryPassenger"
                                                className={
                                                    categoryValue == "RIDE_PASSENGER"
                                                        ? "btn btn-primary btn-sm me-0"
                                                        : "btn btn-soft-secondary btn-sm me-0"
                                                }
                                                onClick={() => setCategory("RIDE_PASSENGER")}
                                            >
                                                Passenger
                                                <ReactTooltip
                                                    id="CategoryPassenger"
                                                    place="top"
                                                    content="Passenger"
                                                    style={{ background: "black" }}
                                                />
                                            </button>{" "}
                                        </div>
                                    </Col>
                                    <Col
                                        xl={3}
                                        lg={3}
                                        style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                                    >
                                        <div className="ms-auto ">
                                            <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                                <h4
                                                    className="card-title mb-1 mt-1"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    Duration
                                                </h4>
                                            </div>
                                            <div
                                                className="mt-4 mb-4"
                                                style={{ textAlign: "center" }}
                                            >
                                                <button
                                                    type="button"
                                                    className={
                                                        filter == ""
                                                            ? "btn btn-primary btn-sm me-1"
                                                            : "btn btn-soft-secondary btn-sm me-1"
                                                    }
                                                    onClick={() => setFilter("")}
                                                    data-tooltip-id="alal"
                                                >
                                                    All
                                                    <ReactTooltip
                                                        id="alal"
                                                        place="top"
                                                        content="All"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        (filter == "CURRENT_DAY" && !modalIsOpen)
                                                            ? "btn btn-primary btn-sm"
                                                            : "btn btn-soft-secondary btn-sm"
                                                    }
                                                    onClick={() => setFilter("CURRENT_DAY")}
                                                    data-tooltip-id="cdcd"
                                                >
                                                    CD
                                                    <ReactTooltip
                                                        id="cdcd"
                                                        place="top"
                                                        content="Current Day"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    className={
                                                        (filter == "CURRENT_WEEK" && !modalIsOpen)
                                                            ? "btn btn-primary btn-sm"
                                                            : "btn btn-soft-secondary btn-sm"
                                                    }
                                                    onClick={() => setFilter("CURRENT_WEEK")}
                                                    data-tooltip-id="cwcw"
                                                >
                                                    CW
                                                    <ReactTooltip
                                                        id="cwcw"
                                                        place="top"
                                                        content="Current Week"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    className={
                                                        (filter == "CURRENT_MONTH" && !modalIsOpen)
                                                            ? "btn btn-primary btn-sm"
                                                            : "btn btn-soft-secondary btn-sm"
                                                    }
                                                    onClick={() => setFilter("CURRENT_MONTH")}
                                                    data-tooltip-id="cmcm"
                                                >
                                                    CM
                                                    <ReactTooltip
                                                        id="cmcm"
                                                        place="top"
                                                        content="Current Month"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    className={
                                                        (filter == "CURRENT_YEAR" && !modalIsOpen)
                                                            ? "btn btn-primary btn-sm"
                                                            : "btn btn-soft-secondary btn-sm"
                                                    }
                                                    onClick={() => setFilter("CURRENT_YEAR")}
                                                    data-tooltip-id="cycy"
                                                >
                                                    CY
                                                    <ReactTooltip
                                                        id="cycy"
                                                        place="top"
                                                        content="Current Year"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    className={
                                                        (filter == "CUSTOM_DURATION" || modalIsOpen)
                                                            ? "btn btn-primary btn-sm"
                                                            : "btn btn-soft-secondary btn-sm"
                                                    }
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                    }}
                                                    data-tooltip-id="dfdf"
                                                >
                                                    <FontAwesomeIcon icon={faClock} />
                                                    <ReactTooltip
                                                        id="dfdf"
                                                        place="top"
                                                        content="Custom Date Selection"
                                                        style={{ background: "black" }}
                                                    />
                                                </button>{" "}
                                            </div>
                                        </div>

                                        {/* <Datepicker/> */}
                                    </Col>

                                    <Col
                                        xl={5}
                                        lg={4}
                                        style={{ borderRight: "1px solid #DFDEDE", padding: 0, marginLeft: "-8px" }}
                                    >
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4
                                                className="card-title mb-1 mt-1"
                                                style={{ textAlign: "center" }}
                                                data-tooltip-id="GlobalSearchRide"
                                            >
                                                Global Search
                                                <ReactTooltip
                                                    id="GlobalSearchRide"
                                                    place="top"
                                                    content="Global Search"
                                                    style={{ background: "black", zIndex: "100" }}
                                                />
                                            </h4>
                                        </div>

                                        <div
                                            className="my-3"
                                            style={{
                                                height: "40px",
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <input
                                                type="text"
                                                style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                                                value={searchText}
                                                onKeyPress={handleKeyPress}
                                                onChange={(e) => {
                                                    setSearchText(e.target.value);
                                                }}
                                                placeholder="Ride number or Ride name"
                                            />
                                            <button
                                                ref={inputRef}
                                                style={{ marginLeft: "5px" }}
                                                className="btn btn-primary me-3"
                                                // type="button"
                                                onClick={handleSearch}
                                            >
                                                <i className="bx bx-search-alt align-middle"></i>
                                            </button>
                                        </div>
                                    </Col>

                                    {/* <Col
                                        xl={2}
                                        lg={2}
                                        style={{ padding: "0px", marginLeft: "-10px" }}
                                    >
                                        <div>
                                            <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                                <h4
                                                    className="card-title mb-1 mt-1"
                                                    style={{ textAlign: "center" }}
                                                    data-tooltip-id="dwn"
                                                >
                                                    Download
                                                    <ReactTooltip
                                                        id="dwn"
                                                        place="top"
                                                        content="Current Year Rides as Excel"
                                                        style={{ background: "black" }}
                                                    />
                                                </h4>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <div
                                                    className="mt-3 mb-3"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div style={{ marginTop: "2px" }}>
                                                        <CSVLink
                                                            data={excelData}
                                                            type="button"
                                                            data-tooltip-id="TripReportTool"
                                                            className="btn btn-soft-secondary btn-sm me-3"
                                                            filename={
                                                                loginType == "ADMIN" ||
                                                                    loginType == "SUPER_ADMIN"
                                                                    ? userFirstName + "'s" + " Trip report.csv"
                                                                    : userName + "'s" + " Trip report.csv"
                                                            }
                                                        >
                                                            <img
                                                                src={tripIcon}
                                                                alt="addIcons"
                                                                style={{ height: "30px", width: "30px" }}
                                                            />
                                                            <ReactTooltip
                                                                id="TripReportTool"
                                                                place="top"
                                                                content="Trip Report"
                                                                style={{ background: "black" }}
                                                            />
                                                        </CSVLink>
                                                    </div>
                                                    <div style={{ marginTop: "2px" }}>
                                                        <CSVLink
                                                            data={ddData}
                                                            type="button"
                                                            className="btn btn-soft-secondary btn-sm me-1"
                                                            filename={
                                                                loginType == "ADMIN" ||
                                                                    loginType == "SUPER_ADMIN"
                                                                    ? userFirstName + "'s" + " km/hr report.csv"
                                                                    : userName + "'s" + " km/hr report.csv"
                                                            }
                                                            data-tooltip-id="distanceDurationReport"
                                                        >
                                                            <img
                                                                src={distanceIcon}
                                                                alt="addIcons"
                                                                style={{ height: "30px", width: "30px" }}
                                                            />
                                                            <ReactTooltip
                                                                id="distanceDurationReport"
                                                                place="top"
                                                                content="Distance / Duration Report"
                                                                style={{ background: "black" }}
                                                            />
                                                        </CSVLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col> */}
                                </Row>
                            </CardHeader>

                            <CardBody style={{ padding: "0px" }}>

                                <Card className="border-0">
                                    <CardHeader className="cardHeader" style={{ paddingLeft: '12px' }}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <h5 className="sub_heading m-0">Driving Behaviour Rating</h5>
                                                <ReactTooltip
                                                    id={"info"}
                                                    place="top"
                                                    content={`
                                                    Driver Behaviour Score is calculated on a scale of 100 and represents a cumulative sum of the 
                                                    Driving Point, Anticipation Point, Self Confidence Point, Driving Skill Point, Driving Style Point, Driver State Point, Mobile Use Point, Mobile Call Point, Overspeed KMS Driven Point, and Acc/Brak/Cor Point
                                                    `}
                                                    multiline={true}
                                                    style={{ backgroundColor: "black", height: 'auto', width: '40vw', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '20px' }}
                                                />


                                            </div>

                                            <div className="ms-auto">
                                                <div style={{ verticalAlign: "middle", display: "flex" }}>

                                                    <div>
                                                        <div className="d-flex mt-1 align-items-center" >
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                                                                Poor (0-80)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                                                                Fair (80-93)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                                                                Good (93-100)
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <button
                      type="button"
                      data-tooltip-id="euser"
                      className={"btn btn-soft-primary btn-sm ms-1"}
                      onClick={exportPDF}
                    >
                      <PiDownloadSimple size="18px" />
                      <ReactTooltip
                        id="euser"
                        place="top"
                        content="Export All Users"
                        style={{ backgroundColor: "black" }}
                      />
                    </button> */}

                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <div className="mb-4" style={{ height: 525, overflowY: "auto" }} >
                                            <div style={{ height: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', padding: '10px' }}>{displayText.DRIVER_BEHAVIOUR_DESCRIPTION}</div>
                                            <Table bordered style={TableStyle.table}>

                                                <thead>
                                                    <tr >
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />{" "}<br />Rating
                                                            <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                        </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving<br /> Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Ride <br />Name<br /><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />{" "}<br />Category<br /><img src={category_image} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Driving <br />Points<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Anticipation <br />Points<br /><img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Self <br />Confidence <br />Points<br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Skill <br />Points<br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving <br />Style <br />Points<br /><img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driver <br />State <br />Points<br /><img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile <br />Screen Usage   <br />Points<br /><img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile <br />Call Usage <br />Points<br /><img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> KMS Driven <br />Points<br /><img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acc/Brak<br />/Cor <br />Points<br /><img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>

                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Ride<br />Travel<br />Period
                                                            <br /><img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                        </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Battery<br />%
                                                            <br /><img src={battery} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                        </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />{" "}<br />Event
                                                            <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {dataLength > 0 && (

                                                    <tbody>
                                                        {(apiData || []).map((apiData, index) => (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} data-tooltip-id={`ETV${index}`} >
                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                        <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                                                            <CircularProgressbar
                                                                                value={apiData.totalEfficiency}
                                                                                text={`${apiData.totalEfficiency}%`}
                                                                                styles={buildStyles({
                                                                                    width: "10px",
                                                                                    textSize: "30px",
                                                                                    pathColor: apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? "#E21212" : apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? "#FFBF00" : "#19543e",
                                                                                    trailColor: "#0095FF",
                                                                                })}
                                                                            ></CircularProgressbar>
                                                                        </div>
                                                                  <ReactTooltip id={`ETV${index}`} place="top" content={apiData.totalEfficiencyTV} style={{ backgroundColor: "black" }} />
                                                                    </div>
                                                                </td>
                                                                <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span
                                                                            data-tooltip-id={`rideTooltip_${index}`}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip
                                                                        id={`rideTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.StatusTemp}
                                                                    />
                                                                </td>
                                                                {/* <td className="lightBlueColor text-nowrap" data-tooltip-id={`categoryTooltip_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.categoryTooltip}`} */}
                                                                {/* <ReactTooltip
                                                                        id={`categoryTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.categoryTooltip}
                                                                    /> */}
                                                                {/* </td> */}
                                                                <td
                                                                    className="lightBlueColor text-nowrap"
                                                                    data-tooltip-id={`categoryTooltip_${index}`}
                                                                    style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}
                                                                    dangerouslySetInnerHTML={{ __html: apiData.categoryTooltip }} // This will render the <br/> as an actual line break
                                                                />
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.drivingScore)} / 20`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallAnticipation)} / 10`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)} / 10`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)} / 10`}</div></td>
                                                                <td className="text-nowrap" style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.DSTP)} / 10`}</div></td>
                                                                <td className="text-nowrap" style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.DSP)} / 10`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.mobileScreenPointNew)} / 5`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.mobileUsePointNew)} / 5`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.totalKmSPoint)} / 10`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.ABCPoint)} / 10`}</div></td>
                                                                <td data-tooltip-id={`time_${index}`} data-tooltip-html={`Start Time: ${apiData.startTime} <br/> End Time: ${apiData.endTime}`} style={{ textAlign: "center", verticalAlign: "middle" }}><img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                                    <ReactTooltip id={`time_${index}`} place="top" effect="solid" backgroundColor="black" multiline={true} style={{ textAlign: 'left' }} />
                                                                </td>
                                                                <td data-tooltip-id={`battery_${index}`} data-tooltip-html={`Battery Level Start: ${apiData.batterylevelStart}% <br/> Battery Level End: ${apiData.batterylevelEnd}%`} style={{ textAlign: "center", verticalAlign: "middle" }}><img src={battery} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                                    <ReactTooltip id={`battery_${index}`} place="top" effect="solid" backgroundColor="black" multiline={true} style={{ textAlign: 'left' }} />
                                                                </td>
                                                                <td data-tooltip-id={`event_${index}`} style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.event}
                                                                    <ReactTooltip id={`event_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.longEvent} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                )}
                                            </Table>

                                            {dataLength === 0 && (
                                                <p
                                                    className="data_not_available"
                                                    style={{
                                                        height: 300,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {displayText.DATA_NOT_AVAILABLE}
                                                </p>
                                            )}

                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="border-0">
                                    <CardHeader className="cardHeader">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="sub_heading m-0">Dashboard for Violation</h5>
                                            <div className="ms-auto">
                                                <div style={{ verticalAlign: "middle", display: "flex" }}>
                                                    <div>
                                                        <div className="d-flex mt-1 align-items-center" >
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                                                                Poor (0-80)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                                                                Fair (80-93)
                                                            </div>
                                                            <div className="me-1 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                                                                Good (93-100)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <button
                                                        type="button"
                                                        data-tooltip-id="euser"
                                                        className={"btn btn-soft-primary btn-sm ms-3"}
                                                        onClick={exportPDFViolation}
                                                    >
                                                        <PiDownloadSimple size="18px" />
                                                        <ReactTooltip
                                                            id="euser"
                                                            place="top"
                                                            content="Export All Users"
                                                            style={{ backgroundColor: "black" }}
                                                        />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <div style={{ height: 425, overflowY: "auto" }} >

                                            <Table bordered style={TableStyle.table}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                                                            <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                                                        </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Ride <br />Name<br /><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Journey<br /><img src={DetailViewd} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                    </tr>
                                                </thead>
                                                <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                                                <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />

                                                {dataLength > 0 && (

                                                    <tbody>
                                                        {(apiData || []).map((apiData, index) => (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} data-tooltip-id={`ETV${index}`} >
                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                        <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                                                            <CircularProgressbar
                                                                                value={apiData.totalEfficiency}
                                                                                text={`${apiData.totalEfficiency}%`}
                                                                                styles={buildStyles({
                                                                                    width: "10px",
                                                                                    textSize: "30px",
                                                                                    pathColor: apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? "#E21212" : apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? "#FFBF00" : "#19543e",
                                                                                    trailColor: "#0095FF",
                                                                                })}
                                                                            ></CircularProgressbar>
                                                                        </div>
                                                                    <ReactTooltip id={`ETV${index}`} place="top" content={apiData.totalEfficiencyTV} style={{ backgroundColor: "black" }} />
                                                                    </div>
                                                                </td>
                                                                <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span data-tooltip-id={`rideTooltip_${index}`} style={{ fontSize: "14px" }} >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip id={`rideTooltip_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.StatusTemp} />
                                                                </td>

                                                                <td className="lightBlueColor" data-tooltip-id={`tkilometer_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                                                                    {apiData.tKiloMeter}
                                                                    <ReactTooltip id={`tkilometer_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tKiloMeterTV} />
                                                                </td>
                                                                <td className="lightBlueColor" data-tooltip-id={`tTravelTime_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                                                                    {apiData.tTravelTime}
                                                                    <ReactTooltip id={`tTravelTime_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tTravelTimeTV} />
                                                                </td>

                                                                <td className="" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.crashCount}</td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalHighRiskCount}`}</td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalMediumRiskCount}`}</td>

                                                                <td data-tooltip-id={`overSpeedDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                                                                    {`${apiData.totalOverSpeedDuration} | ${apiData.totalOverSpeedCount}`}
                                                                    {/* <ReactTooltip id={`overSpeedDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalOverSpeedDurationTV} | ${apiData.totalOverSpeedCount}`} /> */}
                                                                </td>
                                                                <td data-tooltip-id={`mobileScreenDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                                                                    {`${apiData.totalMobileScreenScreenOnDuration} | ${apiData.totalMobileScreenScreenOnCount}`}
                                                                    {/* <ReactTooltip id={`mobileScreenDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalMobileScreenScreenOnDurationTV} | ${apiData.totalMobileScreenScreenOnCount}`} /> */}
                                                                </td>
                                                                <td data-tooltip-id={`mobileCallDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                                                                    {`${apiData.totalMobileUseInAcceptedDuration} | ${apiData.totalMobileUseInAcceptedCount}`}
                                                                    {/* <ReactTooltip id={`mobileCallDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalMobileUseInAcceptedDurationTV} | ${apiData.totalMobileUseInAcceptedCount}`} /> */}
                                                                </td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalAccelerationCount}`}</td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalBrakingCount}`}</td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalCorneringCount}`}</td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                                                                <Link
                                                                    to="/journeyView"
                                                                    state={{
                                                                        userId: apiData.userId,
                                                                        divisionId: apiData.divisionId,
                                                                        rideId: apiData.rideId,
                                                                        rideNo: apiData.rideNo,
                                                                    }}
                                                                    className='m-0 p-0'
                                                                >
                                                                    <span style={{ fontSize: "13.5px", textAlign: "left" }}>
                                                                        View
                                                                    </span>
                                                                </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                )}

                                            </Table>
                                            {dataLength === 0 && (
                                                <p
                                                    className="data_not_available"
                                                    style={{
                                                        height: 300,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {displayText.DATA_NOT_AVAILABLE}
                                                </p>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>



                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="d-flex justify-content-between mb-3">
                            <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                                onClick={previous}
                                style={{
                                    visibility: prevShow ? "visible" : "hidden",
                                    width: "45px",
                                    float: "left",
                                }}
                            >
                                Prev
                            </button>
                            {dataLength > 0 ? (
                                <>
                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div className="pagination ">
                                            <a>{pageNumber}</a>
                                        </div>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        >
                                            of
                                        </p>
                                        <div className="pagination ">
                                            <a>{totalPageCount}</a>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-success btn-sm"
                                        onClick={next}
                                        style={{
                                            visibility: nextShow ? "visible" : "hidden",
                                            float: "hidden",
                                        }}
                                    >
                                        Next
                                    </button>
                                </>
                            ) : null}
                        </div>
                    </Col>
                </Row>


                <DateTimePickerPopup
                    modalOpen={modalIsOpen}
                    modalClose={setIsOpen}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    setFilter={setFilter}
                    filter={filter}
                />
            </Container>
            <ToastContainer style={{ width: "auto" }} />
        </div>
    );
}

export default NewRideList;

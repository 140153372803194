import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
  NavItem,
  Nav,
} from "reactstrap";
/** import Mini Widget data */
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import { useSelector } from "react-redux";
import Loader from "../../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant";
import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";

import i1 from "../../../assets/images/adminDashboard/ad-dr-name.svg"
import i2 from "../../../assets/images/adminDashboard/ad-dr-uname.svg"
import i3 from "../../../assets/images/adminDashboard/ad-dr-rank.svg"
import i4 from "../../../assets/images/adminDashboard/totalRides.svg"
import i5 from "../../../assets/images/adminDashboard/ad-dr-riskAlerts.svg"
import i6 from "../../../assets/images/adminDashboard/ad-dr-otherAlerts.svg"
import i7 from "../../../assets/images/adminDashboard/ad-dr-overspeed.svg"
import i8 from "../../../assets/images/adminDashboard/ad-dr-mobileCall.svg"
import i9 from "../../../assets/images/adminDashboard/ad-dr-mobileScreen.svg"
import i10 from "../../../assets/images/adminDashboard/ad-dr-acceleration.svg"
import i11 from "../../../assets/images/adminDashboard/ad-dr-Braking.svg"
import i12 from "../../../assets/images/adminDashboard/ad-dr-cornering.svg"

import crash from "../../../assets/images/adminDashboard/crash_accident.svg"
import UBIDrivingScore from "../../../assets/images/Ubi Images/UBIDrivingScore.svg"
import UBIAnticipation from "../../../assets/images/Ubi Images/UBIAnticipation.svg"
import UBISelfConfidence from "../../../assets/images/Ubi Images/UBISelfConfidence.svg"
import UBIDrivingSkill from "../../../assets/images/Ubi Images/UBIDrivingSkill.svg"
import UBIDrivingStyle from "../../../assets/images/Ubi Images/UBIDrivingStyle.svg"
import UBIDrivingState from "../../../assets/images/Ubi Images/UBIDrivingState.svg"
import UBIMobileScreenUse from "../../../assets/images/Ubi Images/UBIMobileScreenUse.svg"
import UBIMobileCallUse from "../../../assets/images/Ubi Images/UBIMobileCallUse.svg"
import UBIKmsDriven from "../../../assets/images/Ubi Images/UBIKmsDriven.svg"
import UBIStressStrain from "../../../assets/images/Ubi Images/UBIStressStrain.svg"
import UBIScoreImage from "../../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg"
import tdistance from "../../../assets/images/adminDashboard/ad-2r-distance.svg"
import tduration from "../../../assets/images/adminDashboard/ad-2r-time.svg"



const UnsafeDrivers = () => {
  const {
    layoutType,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    layoutMode,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    layoutMode: state.Layout.layoutMode,
  }));
  
  const [filter, setFilter] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: ""
});
  const [dataLength, setDataLength] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);  
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  
  
  // Unsafe Driver
  const [dataDisplay, setDataDisplay] = useState(false);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const containerIds = ["container1"];
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [summaryData, setSummaryData] = useState([]);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };
  



 

 
  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "#fff",
      height: "20px",
      fontWeight: 600,
      fontSize: "14px",
    },
  };

  // Unsafe Drivers

  const getOverAllSummary = async () => {
    setDataDisplay(null);
    console.log(filter,"filter9jfilterfilterfilter")
    var result = await AdminDashboardController.unsafeDrivers(
      indexStart,
      filter.durationFilter,
      "",
      filter.startDateTime,
      filter.endDateTime
    );
    console.log("getOverAllSummaryNew: ", result);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      if (resultJSONData.length > 0) {
        setDataDisplay(null);
        console.log("getOverAllSummary: ", resultJSONData);
        setDataLength(resultJSONData.length);
        var summaryData = [];
        resultJSONData.map((value, i) => {
          var rank = (pageNumber - 1) * 1000 + (1 + i);
          var firstName = ValueFormat.parseAsString((value.firstName).toUpperCase());
          var username = ValueFormat.parseAsString(value.username) || "-";
          var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides)) || "-";
          var userId = ValueFormat.parseAsString(value.userId);
          var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
          var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
          var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
          var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
          var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
          var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
          var overSpeedPoint = ValueFormat.parseAsFloat(value.overSpeedPoint, 1) || "-";
          var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
          var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
          var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
          var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

          var totalEfficiencyRaw =
            parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
          var totalEfficiencyTV = ValueFormat.parseAsFloat(totalEfficiencyRaw, 2);

          var overallDrivingScoreTooltipValue = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
          var overallAnticipationTooltipValue = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
          var overallSelfConfidenceTooltipValue = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
          var overallDrivingSkillTooltipValue = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
          var dStyleValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
          var dStateValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
          var kmsDrivenTooltipValue = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
          var mobileCallValueTooltipValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
          var mobileScreenValueTooltipValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
          var abcPointTooltipValue = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

          var efficiency = ValueFormat.parseAsFloat(value.drivingEfficiency, 0) || "-";
          var efficiencyTooltipValue = ValueFormat.parseAsFloat(value.drivingEfficiency, 2) || "-";

          

          var totalAccelerationCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 0));

          

          var totalBrakingCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 0));

         

          var totalCorneringCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 0));

          var totalMobileUseInAcceptedCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedCount, 0) || "-";
          var totalMobileUseInAcceptedDistance = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDistance, 0) || "-";
          const parsedNum = parseFloat(value.totalMobileUseInAcceptedDuration);
          var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum) ? parsedNum :  ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";

          var totalMobileScreenScreenOnCount = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnCount, 0) || "-";
          var totalMobileScreenScreenOnDistance = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDistance, 0) || "-";
          const parsedNumMobileScreen = parseFloat(value.totalMobileScreenScreenOnDuration);
          var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";

          var totalOverSpeedCount = ValueFormat.parseAsFloat(value.totalOverSpeedCount, 0) || "-";
          const parsedNumOverSpeed = parseFloat(value.totalOverSpeedDuration);
          var totalOverSpeedDuration = Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMediumRiskCount = ValueFormat.parseAsFloat(value.totalMediumRiskCount, 0) || "-";
          var totalHighRiskCount = ValueFormat.parseAsFloat(value.totalHighRiskCount, 0) || "-";

          var tKiloMeter = ValueFormat.parseAsFloat(value.tKiloMeter, 0) || "-";
          var tTravelTime = ValueFormat.parseAsFloat(value.tTravelTime, 0) || "-";

          var crashCount = ValueFormat.parseAsString(value.crashCount) || "-";

          var tKiloMeterTV = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
          var tTravelTimeTV = ValueFormat.parseAsFloat(value.tTravelTime, 2) || "-";
          var totalOverSpeedDurationTV = ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMobileScreenScreenOnDurationTV = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";
          var totalMobileUseInAcceptedDurationTV = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";

          var resultObject = {
            rank: rank,
            firstName: firstName,
            username: username || "N/A",
            drivingScore: overallDrivingScore,
            totalRides: totalRides,
            userId: userId,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            overSpeedPoint: overSpeedPoint,
            efficiency: efficiency,
            totalEfficiency: totalEfficiency,
            efficiencyTooltipValue: efficiencyTooltipValue,
            crashCount: crashCount,

            overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
            overallAnticipationTooltipValue: overallAnticipationTooltipValue,
            overallSelfConfidenceTooltipValue: overallSelfConfidenceTooltipValue,
            overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
            dStyleValueTooltipValue: dStyleValueTooltipValue,
            dStateValueTooltipValue: dStateValueTooltipValue,
            kmsDrivenTooltipValue: kmsDrivenTooltipValue,
            mobileCallValueTooltipValue: mobileCallValueTooltipValue,
            mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
            abcPointTooltipValue: abcPointTooltipValue,
            totalEfficiencyTV: totalEfficiencyTV,

            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,

            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance: totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration: totalMobileScreenScreenOnDuration,

            totalOverSpeedCount: totalOverSpeedCount,
            totalMediumRiskCount: totalMediumRiskCount,
            totalHighRiskCount: totalHighRiskCount,

            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,

            totalOverSpeedDurationTV: totalOverSpeedDurationTV,
            totalMobileScreenScreenOnDurationTV: totalMobileScreenScreenOnDurationTV,
            totalMobileUseInAcceptedDurationTV: totalMobileUseInAcceptedDurationTV,
            tKiloMeterTV: tKiloMeterTV,
            tTravelTimeTV: tTravelTimeTV
          };
          summaryData.push(resultObject);
          setDataDisplay(
            Object.values(resultObject).some((value) => value !== 0)
          );
        });
      } else {
        setDataDisplay(false);
      }
      handleSummaryData({ data: summaryData, filter: filter.durationFilter });

      setSummaryDataList(summaryData);
    } else if (status == "FAILED") {
      setDataDisplay(false);

      var code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed");
      }
    }
  };

  useEffect(() => {
    getOverAllSummary();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

//   useEffect(() => {
//     setFilter( {...filter,
//         startDateTime: startDateTime,
//         endDateTime: endDateTime } )
// }, [startDateTime, endDateTime]);


  return (
    <React.Fragment>
      <div className="page-content" >
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />

            <Row className="adminmenu-height mb-2">
              <Col xs={12} className="shadow-sm" style={{ position: "fixed", zIndex: 1, backgroundColor: "#fff" }} >
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>

                  <Col
                    xl={5}
                    className="py-2"
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            className={
                              (filter.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilter({
                                ...filter,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            className={
                              (filter.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilter({
                                ...filter,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            className={
                              (filter.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilter({
                                ...filter,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            className={
                              (filter.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilter({
                                ...filter,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filter.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                            //   setCounts(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>
                      

                       <div>
                        <ExportScreenPdf
                      containerIds={containerIds}
                      fileName={userFirstName + "'s" + " Unsafe Drivers"}
                    />
                      </div>
                      <div>
                      <ViewPdf
                        // ADTopRowItemsTemp={ADTopRowItemsTemp}
                        // ADRideBoxCount={ADRideBoxCount}
                        summaryData={summaryData}
                        // riskSummaryData={riskSummaryData}
                        // aggregateData1={aggregateData}
                        // totalDays1={totalDays}
                        filtersADOS={filter}
                      />
                      </div>

                      {/* <Link to={"/exportRisk"}>
                            <button
                              // className="btn btn-soft-primary btn-sm ms-1"
                              className="btn btn-primary btn-sm"
                              data-tooltip-id="err"
                            >
                              Go to Rides & Risks
                              <ReactTooltip
                                id="err"
                                place="bottom"
                                content="Go to Rides & Risks"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>
                          </Link> */}
                    </div>
                  </Col>
                 
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col xl={12}>
              <Col xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <div className="d-flex justify-content-between align-items-center">             
                <h5 className="sub_heading m-0">Unsafe Drivers <span style={{ fontSize: "11px", fontWeight: 400 }}>(Top 25 Poor Rides)</span></h5>
                <div className="ms-auto">
                  <div style={{ verticalAlign: "middle", display: "flex" }}>
                    <div>
                      <div className="d-flex mt-1 align-items-center" >
                        <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                          <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                          Poor (0-80)
                        </div>
                       
                      </div>
                    </div>
                    {/* <button
                      type="button"
                      data-tooltip-id="euser"
                      className={"btn btn-soft-primary btn-sm ms-3"}
                      onClick={exportPDFViolation}
                    >
                      <PiDownloadSimple size="18px" />
                      <ReactTooltip
                        id="euser"
                        place="top"
                        content="Export All Users"
                        style={{ backgroundColor: "black" }}
                      />
                    </button> */}
                  </div>
                </div>
              </div>
            </CardHeader>
            {dataDisplay === true ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                    <tbody>
                      {(summaryDataList || []).map((apiData, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                            apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                              <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }} data-tooltip-id={`ETV${index}`} >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <div style={{ height: 40, width: 40, display: "flex", justifyContent: "center", flexDirection: "column" }} >
                                <CircularProgressbar
                                  value={apiData.totalEfficiency}
                                  text={`${apiData.totalEfficiency}%`}
                                  styles={buildStyles({
                                    width: "10px",
                                    textSize: "30px",
                                    pathColor: apiData.totalEfficiency >= 0 && apiData.totalEfficiency <= 80 ? "#E21212" : apiData.totalEfficiency >= 81 && apiData.totalEfficiency <= 93 ? "#FFBF00" : "#19543e",
                                    trailColor: "#0095FF",
                                  })}
                                ></CircularProgressbar>
                              </div>
                              <ReactTooltip id={`ETV${index}`} place="top" content={apiData.totalEfficiencyTV} style={{ backgroundColor: "black" }} />
                            </div>
                          </td>
                          <td className="lightBlueColor" style={{ paddingLeft: "20px", verticalAlign: "middle" }}><span
                            style={{ fontSize: "14px" }}
                            onMouseEnter={() => [
                              localStorage.setItem(
                                SC_USER_ID,
                                apiData.userId
                              ),
                              localStorage.setItem(
                                SC_LOGIN_USER_FIRST_NAME,
                                apiData.firstName
                              ),
                              localStorage.setItem(
                                SC_USER_USERNAME,
                                apiData.username
                              ),
                            ]}
                          >
                            <Link to={"/user-dashboard"}>{apiData.firstName}</Link></span>
                          </td>
                          <td className="lightBlueColor" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.username}</td>
                          <td className="lightBlueColor" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.totalRides}</td>
                          <td className="lightBlueColor" data-tooltip-id={`tkilometer_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                            {apiData.tKiloMeter}
                            <ReactTooltip id={`tkilometer_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tKiloMeterTV} />
                          </td>
                          <td className="lightBlueColor" data-tooltip-id={`tTravelTime_${index}`} style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>
                            {apiData.tTravelTime}
                            <ReactTooltip id={`tTravelTime_${index}`} place="top" effect="solid" backgroundColor="black" content={apiData.tTravelTimeTV} />
                          </td>
                          <td className="" style={{ textAlign: "center", verticalAlign: "middle", fontSize: "14px" }}>{apiData.crashCount}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalHighRiskCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalMediumRiskCount}`}</td>
                          <td data-tooltip-id={`overSpeedDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalOverSpeedDuration} | ${apiData.totalOverSpeedCount}`}
                            {/* <ReactTooltip id={`overSpeedDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalOverSpeedDurationTV} | ${apiData.totalOverSpeedCount}`} /> */}
                          </td>
                          <td data-tooltip-id={`mobileScreenDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalMobileScreenScreenOnDuration} | ${apiData.totalMobileScreenScreenOnCount}`}
                            {/* <ReactTooltip id={`mobileScreenDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalMobileScreenScreenOnDurationTV} | ${apiData.totalMobileScreenScreenOnCount}`} /> */}
                          </td>
                          <td data-tooltip-id={`mobileCallDurationCount_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>
                            {`${apiData.totalMobileUseInAcceptedDuration} | ${apiData.totalMobileUseInAcceptedCount}`}
                            {/* <ReactTooltip id={`mobileCallDurationCount_${index}`} place="top" effect="solid" backgroundColor="black" content={`${apiData.totalMobileUseInAcceptedDurationTV} | ${apiData.totalMobileUseInAcceptedCount}`} /> */}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalAccelerationCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalBrakingCount}`}</td>
                          <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.totalCorneringCount}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            ) : dataDisplay === false ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                </div>
              </CardBody>
            ) : dataDisplay === "failed" ? (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.ER_1206}
                  </p>
                </div>
              </CardBody>
            ) : (
              <CardBody className="p-0">
                <div style={{ height: 420, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Rating
                          <br /><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} />
                        </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Driving Behaviour <br />Score<br /><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Name <br /><img src={i1} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Username <br /><img src={i2} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total <br />Rides <br /><img src={i4} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Distance <br /> (kms) <br /><img src={tdistance} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Total Duration <br /> (Mins) <br /><img src={tduration} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Crash <br /> (Count) <br /><img src={crash} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="HRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >High Risk<br /> (Count) <br /><img src={i5} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="MRAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Medium Risk<br /> (Count)<br /><img src={i6} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TOC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Overspeed<br /> (Mins | Count)<br /><img src={i7} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMSC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Screen<br />(Mins | Count) <br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TMCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Mobile Call<br />(Mins | Count) <br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TAC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Acceleration<br />(Count) <br /><img src={i10} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TBC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Braking <br />(Count) <br /><img src={i11} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                        <th className="text-nowrap cardHeaderForDBRDFV" data-tooltip-id="TCC" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >Cornering<br />(Count) <br /><img src={i12} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                      </tr>
                    </thead>
                    <ReactTooltip id="HRAC" place="top" content="Total High Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="MRAC" place="top" content="Total Medium Risk Alert Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TOC" place="top" content="Total Overspeed Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMCC" place="top" content="Total Mobile Call Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TMSC" place="top" content="Total Mobile Screen Duration | Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TAC" place="top" content="Total Acceleration Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TBC" place="top" content="Total Braking Count" style={{ backgroundColor: "black" }} />
                    <ReactTooltip id="TCC" place="top" content="Total Cornering Count" style={{ backgroundColor: "black" }} />
                  </Table>
                  <p
                    className="fetch_data"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.FETCHING_DATA}
                  </p>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
               
              </Col>
            </Row>          

          </div>

        </Container>
      </div >
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter} />
    </React.Fragment >
  );
};

export default UnsafeDrivers;

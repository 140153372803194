import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import {
  AdminDashboardRideBoxCount,
  AdminDashboardTopRowItems,
} from "../../../common/data/admin/dashboard.js";
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import OverallSummary from "../OverallSummary/overallSummary.js";
import DrivingBehaviour from "../Charts/multiuserDrivingBehaviour.js";
import { useSelector } from "react-redux";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService.js";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant.js";
import { SC_USER_FIRST_NAME } from "../../../constant/StorageConstant.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "../OverallRiskSummary/overallRiskSummary.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";
import RidesKmHrsScoreCBar from "./RidesKmHrsScoreCBar.js";
import "./Submenu.css";

const OverallSummaryy = () => {
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );
  const [ADRideBoxCount, setADRideBoxCount] = useState(
    AdminDashboardRideBoxCount
  );
  console.log(ADRideBoxCount, "ADRideBoxCountADRideBoxCount");
  
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [totalRidesCount, setTotalRidesCount] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );

  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });

  const [aggregateData, setAggregateData] = useState({
    totalRides: 0,
    totalKilometer: 0,
    totalHighRiskCount: 0,
    totalAlertDataCount: 0,
    totalMediumRiskCount: 0,
    totalTravelTime: 0,
  });
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const getTotalValues = async () => {
    setCount(1);
    setShowLoader((current) => current + 1);
    var result = await AdminDashboardController.adminDashboardController(
      filters.startDateTime,
      filters.endDateTime,
      filters.durationFilter
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("admintopitem:", resultJSON)
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var totalCount = 0;
      var totalKm = 0;
      var totalRide = 0;
      var safeRide = 0;
      var userCountPercentages = [];
      // console.log("AdminTopRows: ",(resultJSONData));
      let totalDays = 0;
      let totalRides = 0;
      let totalKilometer = 0;
      let totalHighRiskCount = 0;
      let totalAlertDataCount = 0;
      let totalMediumRiskCount = 0;
      let totalTravelTime = 0;
      resultJSONData.map((apiResultTemp) => {
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.totalUserCount)
        );
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.registeredUserCount)
        );

        totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.noOfDays));

        const overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });

        const overAllRideBoxCountList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxCountList
        );

        overAllRideBoxCountList.forEach((apiData) => {
          totalRides += ValueFormat.parseAsInt(apiData.totalRides);
          totalKilometer +=
            parseFloat(apiData.totalKilometer) == undefined ||
            parseFloat(apiData.totalKilometer) == null
              ? 0
              : parseFloat(apiData.totalKilometer);
          totalHighRiskCount += ValueFormat.parseAsInt(
            apiData.totalHighRiskCount
          );
          totalAlertDataCount += ValueFormat.parseAsInt(
            apiData.totalAlertDataCount
          );
          totalMediumRiskCount += ValueFormat.parseAsInt(
            apiData.totalMediumRiskCount
          );
          totalTravelTime +=
            parseFloat(apiData.totalTravelTime) == undefined ||
            parseFloat(apiData.totalTravelTime) == null
              ? 0
              : parseFloat(apiData.totalTravelTime);
        });

        let overAllRideBoxTotalRidesCount = {
          totalRides: 0,
          count: [
            { totalRides: 0, rideBox: 1, percentage: 0, totalKm: 0, totalTravelTime: 0 },
            { totalRides: 0, rideBox: 2, percentage: 0, totalKm: 0, totalTravelTime: 0 },
            { totalRides: 0, rideBox: 3, percentage: 0, totalKm: 0, totalTravelTime: 0 },
            { totalRides: 0, rideBox: 4, percentage: 0, totalKm: 0, totalTravelTime: 0 },
            { totalRides: 0, rideBox: 5, percentage: 0, totalKm: 0, totalTravelTime: 0 },
            { totalRides: 0, rideBox: 6, percentage: 0, totalKm: 0, totalTravelTime: 0 },
          ],
        };
        
        // Calculate total rides
        let overAllTotalRides = 0;
        overAllRideBoxCountList.forEach((val) => {
          overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
        });
        overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;
        
        // Create a map for rideBox indices
        const rideBoxMap = {};
        overAllRideBoxTotalRidesCount.count.forEach((item, index) => {
          rideBoxMap[item.rideBox] = index;
        });
        
        // Update count array using rideBox values
        overAllRideBoxCountList.forEach((val) => {
          const rideBoxIndex = rideBoxMap[val.rideBox];
          if (rideBoxIndex !== undefined) {
            overAllRideBoxTotalRidesCount.count[rideBoxIndex] = {
              ...overAllRideBoxTotalRidesCount.count[rideBoxIndex],
              totalRides: val.totalRides,
              rideBox: val.rideBox,
              percentage: ((ValueFormat.parseAsInt(val.totalRides) / overAllRideBoxTotalRidesCount.totalRides) * 100).toFixed(1),
              totalKm: val.totalKilometer,
              totalTravelTime: val.totalTravelTime,
            };
          } else {
            console.warn(`rideBox ${val.rideBox} not found in count array`);
          }
        });
        
        setADRideBoxCount(overAllRideBoxTotalRidesCount);
        
        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);
        var totalDeactivatedUsersCount = ValueFormat.parseAsInt(apiResultTemp.deactivatedUserCount);
        setTotalRidesCount(totalRide);
        setTotalSafeRidePer(ValueFormat.parseAsFloat((safeRide / totalRide) * 100),0);

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );

        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
            } else if (staticObjectTemp.type === "DEACTIVATED") {
              isTypeMatched = true;
              totalCount = totalDeactivatedUsersCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
        // console.log("rideBoxListLen", ADTopRowItemsTemp)
      });
      setAggregateData({
        totalRides,
        totalKilometer,
        totalHighRiskCount,
        totalAlertDataCount,
        totalMediumRiskCount,
        totalTravelTime,
      });
      
      setTotalDays(totalDays);

      setUserUsagePer(userCountPercentages);
    } else {
      // const code = resultJSON.error.code;

      // if (code == "E1206") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      console.log("Status failed");
    }
  };

  const containerIds = ["container1", "container2"];

  useEffect(() => {
    getTotalValues();
  }, [
    filters.durationFilter,
    indexStart,
    filters.startDateTime,
    filters.endDateTime,
  ]);

  const [summaryData, setSummaryData] = useState([]);
  const [riskSummaryData, setRiskSummaryData] = useState([]);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };
  const handleRiskSummaryData = (data) => {
    setRiskSummaryData(data);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{
                  position: "fixed",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
                className="shadow-sm"
              >
                <Row>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>

                  <Col
                    className="py-2"
                    xl={5}
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>

                      <ExportScreenPdf
                        containerIds={containerIds}
                        fileName={userFirstName + "'s" + " Overall Summary"}
                      />
                      <ViewPdf
                        ADTopRowItemsTemp={ADTopRowItemsTemp}
                        ADRideBoxCount={ADRideBoxCount}
                        summaryData={summaryData}
                        riskSummaryData={riskSummaryData}
                        aggregateData1={aggregateData}
                        totalDays1={totalDays}
                        filtersADOS={filters}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 12px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    All Users
                    {/* <Link to="/myusertabledata">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody style={{}} className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr
                              key={index} className="">
                              <td
                                style={{
                                  padding: "4.7px",
                                  paddingLeft: '12px'
                                }}
                              >
                                {item.title}
                                <AiOutlineExclamationCircle
                                  style={{
                                    marginLeft: "5px",
                                    width:"15px",
                                    height:"15px",
                                  }}
                                  data-tooltip-id={"snpTooltipContent" + index}
                                />
                                <ReactTooltip
                                  id={"snpTooltipContent" + index}
                                  place="top"
                                  content={<span>{item.description}</span>}
                                  style={{ backgroundColor: "black" }}
                                />
                              </td>
                              <td
                                style={{
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 60 }}
                                >
                                  {item.totalCount}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{padding: "10px 10px"}}
                    className="sub_heading cardHeader"
                  >
                    Green Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                  className="body_text"
                    style={{ padding: "10px", height: '205px' }}
                  >
                    <div className="mt-3">
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{textAlign: "center" }}
                          >
                            C1
                          </span>
                          <span >
                            {ADRideBoxCount.count[0].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[0].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Safe Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[0].totalRides} /{" "}
                          {ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            C2
                          </span>
                          <span >
                            {ADRideBoxCount.count[1].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[1].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Sportive Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[1].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Amber Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>

                  <CardBody
                    style={{ padding: "10px", height: '205px' }}
                    className="body_text"
                  >
                    <div className="mt-3">
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            C3
                          </span>
                          <span >
                            {ADRideBoxCount.count[2].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[2].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Quite Safe Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[2].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            C4
                          </span>
                          <span >
                            {ADRideBoxCount.count[3].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[3].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Reckless Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[3].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader 
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Red Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", height: '205px' }}
                    className="body_text"
                  >
                    <div className="mt-3">
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span style={{textAlign: "center" }}>
                          C5
                        </span>
                        <span >
                          {ADRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Quite Dangerous Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[4].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            C6
                          </span>
                          <span >
                            {ADRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[5].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Dangerous Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[5].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <RidesKmHrsScoreCBar aggregateData={aggregateData} totalDays={totalDays}  />

            <OverallSummary filter={filters} receiveSummaryData={handleSummaryData} />
            </div>
            <div id="container2">

            {/* <OverallRiskSummary
              filter={filters}
              receiveRiskSummaryData={handleRiskSummaryData}
            /> */}

            <DrivingBehaviour loaderCount={setShowLoader} filter={filters} />
            </div>

            <DateTimePickerPopup
              modalOpen={modalIsOpen}
              modalClose={setIsOpen}
              setFilter={setFilter}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              filter={filters.durationFilter}
            />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OverallSummaryy;

import React, { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import { Document, Line, PDFViewer, Page, Rect, Svg, Text, View, Image } from "@react-pdf/renderer";
import PdfFooterV1 from "../Components/pdfFooterV1";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfHeading from "../Components/pdfHeading";
import ValueFormat from "../../../util/ValueFormat";
import UtilDateTime from "../../../util/UtilDateTime";

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";
import { displayText } from "../../../constant/MessageConstant";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

export default function AdUnSafeDriversPdf({
    summaryData,
    filtersADOS
 
}) {
  const [showLoader, setShowLoader] = useState();
  const heading = "Admin Dashboard (Unsafe Drivers)";
  const fontBold = { fontFamily: "Helvetica-Bold" };
  const months1 = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var currentDate = new Date();
  const reversedDateFormat =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    months1[currentDate.getMonth()] +
    "-" +
    currentDate.getFullYear();
  let period = "";
  let startDate = "";
  let endDate = "";
  if (filtersADOS.durationFilter === "CURRENT_YEAR") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentYear(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_MONTH") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentMonth(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_WEEK") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentWeek1(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_DAY") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentDay(filtersADOS.startDateTime);   
    endDate = "";
  } else {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = filtersADOS.startDateTime.split(" ")[0];
    endDate = filtersADOS.endDateTime.split(" ")[0];
  }
  const periodFormat = [period, startDate, endDate];


  const titleHead1 = [
    "Total Days",
    "Total Rides",
    "Total Distance",
    "Total Duration",
    "Total Red Alerts",
    "Total Amber Alerts",
  ];


  const originalData = summaryData.data || [
    {
      ABCPoint: "-",
      DSP: "-",
      DSTP: "-",
      drivingScore: "-",
      efficiency: "0",
      efficiencyTooltipValue: "0",
      firstName: "-",
      mobileScreenPointNew: "-",
      mobileUsePointNew: "-",
      overallAnticipation: "-",
      overallDrivingSkill: "-",
      overallSelfConfidence: "-",
      rank: "-",
      totalKmSPoint: "-",
      totalRides: "-",
      userId: "-",
      username: "-",
      tKiloMeterTV: "-",
      tTravelTimeTV: "-",
      totalHighRiskCount: "-",
      totalMediumRiskCount: "-",
      totalOverSpeedDurationTV: "-",
      totalOverSpeedCount: "-",
      totalMobileScreenScreenOnDurationTV: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileUseInAcceptedDurationTV: "-",
      totalMobileUseInAcceptedCount: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
    },
  ];
  const dupData = [...originalData]
  const data = originalData.map((item) => {
    // Extract and parse the necessary values
    const efficiencyValues = [
      parseFloat(item.drivingScore),
      parseFloat(item.overallAnticipation),
      parseFloat(item.overallSelfConfidence),
      parseFloat(item.overallDrivingSkill),
      parseFloat(item.DSTP),
      parseFloat(item.DSP),
      parseFloat(item.mobileScreenPointNew),
      parseFloat(item.mobileUsePointNew),
      parseFloat(item.totalKmSPoint),
      parseFloat(item.ABCPoint),
    ];

    // Calculate the sum of efficiency values
    const sum = efficiencyValues.reduce((total, value) => total + (value || 0), 0);

    // Determine rank based on sum
    let rank;
    if (sum >= 0 && sum <= 80) {
      rank = "Poor";
    } else if (sum >= 81 && sum <= 93) {
      rank = "Fair";
    } else {
      rank = "Good";
    }

    return {
      ...item,
      efficiency: sum.toFixed(0),
      rank, // Update rank
      drivingScore: `${item.drivingScore} / 20`,
      overallAnticipation: `${item.overallAnticipation} / 10`,
      overallSelfConfidence: `${item.overallSelfConfidence} / 10`,
      overallDrivingSkill: `${item.overallDrivingSkill} / 10`,
      DSTP: `${item.DSTP} / 10`,
      DSP: `${item.DSP} / 10`,
      mobileScreenPointNew: `${item.mobileScreenPointNew} / 5`,
      mobileUsePointNew: `${item.mobileUsePointNew} / 5`,
      totalKmSPoint: `${item.totalKmSPoint} / 10`,
      ABCPoint: `${item.ABCPoint} / 10`,
    };
  });

  const chunks = [];
  const firstChunkSize = 38;
  const subsequentChunkSize = 55;

  for (let i = 0; i < data.length; i += subsequentChunkSize) {
    if (i === 0) {
      chunks.push(data.slice(0, firstChunkSize));
      i = firstChunkSize - subsequentChunkSize;
    } else {
      chunks.push(data.slice(i, i + subsequentChunkSize));
    }
  }

  
  const chunksViolation = [];
  const firstChunkSizeViolation = 56;
  const subsequentChunkSizeViolation = 56;

  for (let i = 0; i < data.length; i += subsequentChunkSizeViolation) {
    if (i === 0) {
      chunksViolation.push(data.slice(0, firstChunkSizeViolation));
      i = firstChunkSizeViolation - subsequentChunkSizeViolation;
    } else {
      chunksViolation.push(data.slice(i, i + subsequentChunkSizeViolation));
    }
  }

  const violationCol = [
    "rank",
    "efficiency",
    "firstName",
    "username",
    "totalRides",
    "tKiloMeterTV",
    "tTravelTimeTV",
    "crashCount",
    "totalHighRiskCount",
    "totalMediumRiskCount",
    "totalOverSpeedDurationTV",
    "totalOverSpeedCount",
    "totalMobileScreenScreenOnDurationTV",
    "totalMobileScreenScreenOnCount",
    "totalMobileUseInAcceptedDurationTV",
    "totalMobileUseInAcceptedCount",
    "totalAccelerationCount",
    "totalBrakingCount",
    "totalCorneringCount"
  ];

  const violationTitle = {
    rank: "\n \nRating",
    efficiency: "Driving \nBehaviour \nScore",
    firstName: "\n\nFirst Name",
    username: "\n\nUsername",
    totalRides: "\nTotal \nRides",
    tKiloMeterTV: "Total \nDistance\n (kms)",
    tTravelTimeTV: "Total\n Duration \n(Mins)",
    crashCount: "\nCrash\n(Count)",
    totalHighRiskCount: "\nHigh Risk\n (Count)",
    totalMediumRiskCount: "\nMedium Risk\n (Count)",
    totalOverSpeedDurationTV: "\nOverspeed\n (Mins)",
    totalOverSpeedCount: "\nOverspeed\n (Count)",
    totalMobileScreenScreenOnDurationTV: "Mobile\nScreen\n(Mins)",
    totalMobileScreenScreenOnCount: "Mobile\nScreen\n(Count)",
    totalMobileUseInAcceptedDurationTV: "\nMobile Call \n(Mins)",
    totalMobileUseInAcceptedCount: "\nMobile Call \n(Count)",
    totalAccelerationCount: "\nAcceleration\n (Count)",
    totalBrakingCount: "\nBraking\n (Count)",
    totalCorneringCount: "\nCornering \n(Count)",
  };

  const violationImages = {
    rank: rank,
    firstName: firstName,
    username: username,
    efficiency: efficiency,
    totalRides: totalRides,
    tKiloMeterTV: tdistance,
    tTravelTimeTV: tduration,
    crashCount: crash,
    totalHighRiskCount: i5,
    totalMediumRiskCount: i6,
    totalOverSpeedDurationTV: i7,
    totalOverSpeedCount: i7,
    totalMobileScreenScreenOnDurationTV: i9,
    totalMobileScreenScreenOnCount: i9,
    totalMobileUseInAcceptedDurationTV: i8,
    totalMobileUseInAcceptedCount: i8,
    totalAccelerationCount: i10,
    totalBrakingCount: i11,
    totalCorneringCount: i12

  };


  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
         

          {chunksViolation.map((chunk, pageIndex) => (
            <Page key={pageIndex} size="A2">
              <PdfHeaderV1 />
              <View style={{ padding: 30, paddingTop: 10 }}>
                <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                />
                {/* New Violation Dashboard Table */}
                <View style={{ marginBottom: 5, paddingVertical: 15 }}>
                  <Text style={{ fontSize: 13, marginBottom: 7 }}>
                    Unsafe Drivers <Text style={{ fontSize: 10}}>(Top 25 Poor Rides)</Text>
                  </Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "70px",
                        marginTop: 5,
                      }}
                    >
                      {violationCol.map((column, index) => (
                        <View
                          key={index}
                          style={{
                            flex: column === "firstName" ? 1.7 : 1,
                            textAlign: 'center',
                            justifyContent: "center",
                            backgroundColor: globalPDF.GREY_COLOR,
                            fontSize: "9px",
                            border: "1px solid lightgrey",
                            borderLeft:
                              index === 0 ? "1px solid lightgrey" : "none",
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                            <View style={{ height: '45px', paddingTop: 7 }}>
                              <Text style={fontBold} break> {violationTitle[column]} </Text>
                            </View>
                            <View style={{ height: '25px' }}>
                              <Image src={violationImages[column]} style={{ width: "15px", height: "15px" }} />
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                    {chunk.map((item, rowIndex) => (
                      <View
                        key={rowIndex}
                        style={{ flexDirection: "row", height: "25px" }}
                      >
                        {violationCol.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              flex: column === "firstName" ? 1.7 : 1,
                              justifyContent: "center",
                              fontSize: 9,
                              backgroundColor: column === "totalRides" || column === "tKiloMeterTV" || column === "tTravelTimeTV" || column === "firstName" || column === "username" ? globalPDF.LIGHT_BLUE_COLOR : "transparent",
                              borderLeft:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                              borderRight:
                                colIndex === 6
                                  ? item[column] !== undefined
                                    ? "1px solid lightgrey"
                                    : "none"
                                  : colIndex === violationCol.length - 1
                                    ? "1px solid lightgrey"
                                    : "none",
                              borderBottom:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                textAlign: "left",
                                paddingLeft: 10
                              }}
                              break
                            >
                              {item[column]}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>

              </View>
              {/* <PdfFooterV1 pageNumber={pageIndex + 1} totalPages={chunks.length + chunksViolation.length} /> */}
              <PdfFooterV1 pageNumber={pageIndex + 1} totalPages={chunksViolation.length} />
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}